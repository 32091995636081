import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CellEditing } from '@unique/component-library';

export interface MagicTableState {
  editingCell: CellEditing | null;
}

const initialState: MagicTableState = {
  editingCell: null,
};

export const magicTableSlice = createSlice({
  name: 'magicTable',
  initialState,
  reducers: {
    updateEditingCell: (state, action: PayloadAction<CellEditing>) => {
      state.editingCell = action.payload;
    },
  },
});

export const { updateEditingCell } = magicTableSlice.actions;

export default magicTableSlice.reducer;
